import './index.scss';

const header = document.querySelector('.header');

document.addEventListener('DOMContentLoaded', () => {
  const mobileMenuBtn = document.querySelector('.header__mobile-nav-toggle');

  mobileMenuBtn.addEventListener('click', () => {
    mobileMenuBtn.classList.toggle('header__mobile-nav-toggle--active');
    header.classList.toggle('header--nav--open');
    document.body.classList.toggle('nav--open');
    document.body.classList.toggle('overflow');
    document.documentElement.classList.toggle('overflow');
  });

  const servicesMenuBtn = document.querySelector('.header__subnav__btn');
  const servicesMenu = document.querySelector('.header__subnav');

  if (servicesMenuBtn && window.innerWidth <= 1024) {
    servicesMenuBtn.addEventListener('click', () => servicesMenu.classList.toggle('open'));
  }
});

window.addEventListener(
  'scroll',
  () => {
    let scrollPoint = 0;

    if (document.getElementsByTagName('html')[0].classList.contains('notify-banner-widget')) {
      scrollPoint = 40;
    }

    if (document.documentElement.scrollTop > scrollPoint) {
      header.classList.add('header--scrolled');
    } else {
      header.classList.remove('header--scrolled');
    }
  },
  false,
);
